<tup-mat-content-wrapper  gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33">
    <tup-mat-title-bar title="Start Building your target audience"></tup-mat-title-bar>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle"  gap="33">
    <div
      class="crumb-bar"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="13px"
    >
      <h4 class="mat-h4">Browse by category</h4>
      <p class="mat-caption"><a href="...">All Categories</a></p>
      <p>&gt;</p>
    </div>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
    <tup-mat-card
    *ngFor="let card of cards"
    fxLayout="column"
  >
    <div
      class="display-card-image"
      [ngStyle]="{
        'background-image':
          'url(assets/overlay.png),url(' + card.image + ')'
      }"
    ></div>
    <div fxFlex class="display-card-content" fxLayout="column">
      <h4 class="mat-h4">{{ card.title }}</h4>
      <p fxFlex class="mat-body">{{ card.content }}</p>
      <div fxLayout="row" fxLayoutAlign="end">
        <button mat-stroked-button color="primary" tupMatButton appearance="large">
          Start
        </button>
      </div>
    </div>
  </tup-mat-card>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper >
