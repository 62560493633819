<tup-mat-content-wrapper  gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper position="first" gap="33" overflow="visible">
    <h2>Tables</h2>
    <ul>
      <li>Titles should always be left aligned</li>
      <li>Number values right aligned</li>
      <li>Text values left aligned</li>
      <li>Dates left aligned</li>
    </ul>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="middle" gap="33" overflow="auto">
    <h3>Rounded table using only CSS</h3>

    <div fxLayout="row">

      <div fxFlex fxLayout="column" class="table-example-1">


        <table mat-table [dataSource]="dataSource_1" class="mat-elevation-z3">
          <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                <mat-checkbox tupMatCheckbox checked="true"></mat-checkbox>

                <mat-form-field tupMatFormField tupMatMiniSelectContainer appearance="outline">
                  <mat-select value="100">
                    <mat-option value="25">25</mat-option>
                    <mat-option value="50">50</mat-option>
                    <mat-option value="100">100</mat-option>
                    <mat-option value="500">500</mat-option>
                    <mat-option value="1000">1000</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </th>
            <td mat-cell *matCellDef>
              <mat-checkbox tupMatCheckbox></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Campaign Name</th>
            <td mat-cell *matCellDef="let element" class="tup-mat-cell-strong">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="audience">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">Audience</th>
            <td mat-cell *matCellDef="let element" class="align-right">{{ element.audience }}</td>
          </ng-container>

          <ng-container matColumnDef="responses">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">Responses</th>
            <td mat-cell *matCellDef="let element" class="align-right">
              {{ element.responses }}
            </td>
          </ng-container>

          <ng-container matColumnDef="percent">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">%</th>
            <td mat-cell *matCellDef="let element" class="align-right">{{ element.percent }}</td>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Created By</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Created On</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdOn }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong"></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>more_vert</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns_1" class="tup-mat-cell-strong"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_1"></tr>
        </table>

      </div>

      <div class="css-code">
        <pre>
table {{'{'}}
  border-radius: 0.3125rem;
  margin: 16px;
  overflow: hidden;
}
        </pre>
      </div>

    </div>
    <h3>Rounded table directives using only CSS</h3>

    <div fxLayout="row">

      <div fxFlex fxLayout="column" class="table-example-2">


        <mat-table [dataSource]="dataSource_1" class="mat-elevation-z3">
          <ng-container matColumnDef="selected">
            <mat-header-cell *matHeaderCellDef>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                <mat-checkbox tupMatCheckbox checked="true"></mat-checkbox>

                <mat-form-field tupMatFormField tupMatMiniSelectContainer appearance="outline">
                  <mat-select value="100">
                    <mat-option value="25">25</mat-option>
                    <mat-option value="50">50</mat-option>
                    <mat-option value="100">100</mat-option>
                    <mat-option value="500">500</mat-option>
                    <mat-option value="1000">1000</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef>
              <mat-checkbox tupMatCheckbox></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Campaign Name</mat-header-cell>
            <mat-cell *matCellDef="let element" class="tup-mat-cell-strong">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="audience">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">Audience</mat-header-cell>
            <mat-cell *matCellDef="let element" class="align-right">{{ element.audience }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="responses">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">Responses</mat-header-cell>
            <mat-cell *matCellDef="let element" class="align-right">
              {{ element.responses }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="percent">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong align-right">%</mat-header-cell>
            <mat-cell *matCellDef="let element" class="align-right">{{ element.percent }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Created By</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong">Created On</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdOn }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="tup-mat-cell-strong"></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon>more_vert</mat-icon>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns_1" class="tup-mat-cell-strong"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns_1"></mat-row>
        </mat-table>

      </div>

      <div class="css-code">
        <pre>
mat-table {{'{'}}
  border-radius: 0.3125rem;
  margin: 16px;
  overflow: hidden;
}
        </pre>
      </div>

    </div>

    <div fxLayout="row">
      <div fxFlex class="paginator-example">
        <tup-mat-paginator pageIndex="3" pageSize="10" length="100" [pageSizeOptions]="[10, 25, 50, 100]"></tup-mat-paginator>
      </div>
    </div>

    <h3>Rounded table using `tup-mat-card` & CSS</h3>

    <div fxLayout="row">

      <div fxFlex class="table-example-3">

        <tup-mat-card>
        <table mat-table [dataSource]="dataSource_1" class="mat-elevation-z3">
          <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                <mat-checkbox tupMatCheckbox checked="true"></mat-checkbox>

                <mat-form-field tupMatFormField tupMatMiniSelectContainer appearance="outline">
                  <mat-select value="100">
                    <mat-option value="25">25</mat-option>
                    <mat-option value="50">50</mat-option>
                    <mat-option value="100">100</mat-option>
                    <mat-option value="500">500</mat-option>
                    <mat-option value="1000">1000</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </th>
            <td mat-cell *matCellDef>
              <mat-checkbox tupMatCheckbox></mat-checkbox>
            </td>
          </ng-container>


          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Campaign Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="audience">
            <th mat-header-cell *matHeaderCellDef class="align-right">Audience</th>
            <td mat-cell *matCellDef="let element" class="align-right">{{ element.audience }}</td>
          </ng-container>

          <ng-container matColumnDef="responses">
            <th mat-header-cell *matHeaderCellDef class="align-right">Responses</th>
            <td mat-cell *matCellDef="let element" class="align-right">
              {{ element.responses }}
            </td>
          </ng-container>

          <ng-container matColumnDef="percent">
            <th mat-header-cell *matHeaderCellDef class="align-right">%</th>
            <td mat-cell *matCellDef="let element" class="align-right">{{ element.percent }}</td>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef>Created On</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdOn }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>more_vert</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_1"></tr>
        </table>
        </tup-mat-card>

      </div>

      <div class="css-code">
        <pre>
.tup-mat-card {{'{'}}
  padding: 0px;

  table {{'{'}}
    width: 100%;
  }
}
        </pre>
      </div>

    </div>

    <h3>Scrollable table (using directive elements)</h3>

    <div class="scrollable-table">
      <mat-table [dataSource]="dataSource_1" class="mat-elevation-z3">
        <ng-container matColumnDef="selected" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <mat-checkbox tupMatCheckbox checked="true"></mat-checkbox>

              <mat-form-field tupMatFormField tupMatMiniSelectContainer appearance="outline">
                <mat-select value="100">
                  <mat-option value="25">25</mat-option>
                  <mat-option value="50">50</mat-option>
                  <mat-option value="100">100</mat-option>
                  <mat-option value="500">500</mat-option>
                  <mat-option value="1000">1000</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef>
            <mat-checkbox tupMatCheckbox></mat-checkbox>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Campaign Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="audience">
          <mat-header-cell *matHeaderCellDef class="align-right">Audience</mat-header-cell>
          <mat-cell *matCellDef="let element" class="align-right">{{ element.audience }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="responses">
          <mat-header-cell *matHeaderCellDef class="align-right">Responses</mat-header-cell>
          <mat-cell *matCellDef="let element" class="align-right">
            {{ element.responses }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="percent">
          <mat-header-cell *matHeaderCellDef class="align-right">%</mat-header-cell>
          <mat-cell *matCellDef="let element" class="align-right">{{ element.percent }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.createdBy }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <mat-header-cell *matHeaderCellDef>Created On</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.createdOn }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns_1; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns_1"></mat-row>
      </mat-table>

    </div>
    <tup-mat-paginator pageIndex="3" pageSize="10" length="100" [pageSizeOptions]="[10, 25, 50, 100]" class="tup-mat-paginator-tiny">
    </tup-mat-paginator>

    <h3>Scrollable table (using table elements)</h3>

    <div class="scrollable-table">
      <table mat-table [dataSource]="dataSource_1" class="mat-elevation-z3">
        <ng-container matColumnDef="selected" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <mat-checkbox tupMatCheckbox checked="true"></mat-checkbox>

              <mat-form-field tupMatFormField tupMatMiniSelectContainer appearance="outline">
                <mat-select value="100">
                  <mat-option value="25">25</mat-option>
                  <mat-option value="50">50</mat-option>
                  <mat-option value="100">100</mat-option>
                  <mat-option value="500">500</mat-option>
                  <mat-option value="1000">1000</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef>
            <mat-checkbox tupMatCheckbox></mat-checkbox>
          </td>
        </ng-container>


        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Campaign Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="audience">
          <th mat-header-cell *matHeaderCellDef class="align-right">Audience</th>
          <td mat-cell *matCellDef="let element" class="align-right">{{ element.audience }}</td>
        </ng-container>

        <ng-container matColumnDef="responses">
          <th mat-header-cell *matHeaderCellDef class="align-right">Responses</th>
          <td mat-cell *matCellDef="let element" class="align-right">
            {{ element.responses }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percent">
          <th mat-header-cell *matHeaderCellDef class="align-right">%</th>
          <td mat-cell *matCellDef="let element" class="align-right">{{ element.percent }}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef>Created On</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdOn }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns_1; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns_1"></tr>
      </table>

    </div>
    <tup-mat-paginator pageIndex="3" pageSize="10" length="100" [pageSizeOptions]="[10, 25, 50, 100]">
    </tup-mat-paginator>

  </tup-mat-content-wrapper>

</tup-mat-content-wrapper>
