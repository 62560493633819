import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page1',
  templateUrl: './page1.component.html',
  styleUrls: ['./page1.component.scss']
})
export class Page1Component implements OnInit {
  cards: any = [
    {
      image:"assets/picture1.jpg",
      title:"Insights & Analytics",
      content:"Phasellus lorem libero, viverra at interdum interdum, lobortis quis sem. Mauris imperdiet diam nisi, id ultrices leo eleifend porttitor."
    },
    {
      image:"assets/picture2.jpg",
      title:"Audio",
      content:"Nullam molestie placerat libero non interdum. Mauris non sodales mauris. Curabitur non ligula tristique, fermentum leo vel, feugiat nibh."
    },
    {
      image:"assets/picture3.jpg",
      title:"Out of Home",
      content:"Donec molestie ac justo condimentum interdum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
    },
    {
      image:"assets/picture4.jpg",
      title:"Other Things",
      content:"iam ut fermentum risus, vel hendrerit arcu. Proin tempor, nulla sit amet fringilla euismod, leo metus sagittis tortor."
    }
  ];

  constructor() { }

  ngOnInit(): void {
    
  }

}
