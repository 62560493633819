import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fake-account-menu',
  templateUrl: './fake-account-menu.component.html',
  styleUrls: ['./fake-account-menu.component.scss']
})
export class FakeAccountMenuComponent implements OnInit {

  @Input() sideNavMode = false;
  @Input() sidebarOpened = false;

  user = {attributes: {name: 'John Dough', email:'forgotmy@ownemail.com'}}
  currentContainer = "Telmar";

  constructor() { }

  ngOnInit(): void {
  }

}
