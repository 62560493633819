<mat-divider *ngIf="sidebarOpenState"></mat-divider>

<div *ngIf="sidebarOpenState; else reducedCopyright" class="footer-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="footer-wrapper">
        <p class="mat-small">Powered By:</p>
        <p class="mat-small">&copy; Telmar {{ currentYear }}</p>
        <p> {{ appVersion }}</p>
    </div>
</div>

<ng-template #reducedCopyright>
  <div class="footer-wrapper">
    <mat-icon>copyright</mat-icon>
  </div>
</ng-template>
