<tup-mat-content-wrapper  gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33">
    <h2>Layout</h2>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle"  gap="33" overflow="auto">
    <tup-mat-card>
      <h3>TUP Mat Card</h3>
      <div>
        A content
        <p>
          inside the card
        </p>
      </div>
    </tup-mat-card>
    <tup-mat-card>
      <mat-card-title tupMatCardTitle>TUP Mat Card Title</mat-card-title>
      <div>
        <span>Card with TUP Mat Card Title</span>
      </div>
    </tup-mat-card>
    <tup-mat-card style="max-width: 400px; padding: 0;">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar></div>
          <mat-card-title>Use <code>mat-card-*</code> elements</mat-card-title>
          <mat-card-subtitle>wrapped inside of a <code>tup-mat-card</code></mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="/assets/shiba2.jpg" alt="Photo of a Shiba Inu">
        <mat-card-content>
          <p>
            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
            A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
            bred for hunting.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button>LIKE</button>
          <button mat-button>SHARE</button>
        </mat-card-actions>
      </mat-card>
    </tup-mat-card>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper >
