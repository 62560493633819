<div fxFill fxLayout="column">
  <tup-mat-content-wrapper position="first">
    <tup-mat-title-bar title="My insights reports"></tup-mat-title-bar>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="first">
    <div fxLayout="row" fxLayoutGap="13px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start">
      <div fxLayout="row" fxFlex fxLayoutAlign="start end">
        <tup-mat-ichabods-tabs [ichabodsBody]="ichabodsBody"></tup-mat-ichabods-tabs>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="13px">
        <mat-form-field
          appearance="fill"
          tupMatFormField
          tupMatRoundedSelect
        >
          <mat-label>Insight</mat-label>
          <mat-select value="All">
            <mat-option value="All">All</mat-option>
            <mat-option value="Men">Men</mat-option>
            <mat-option value="Women">Women</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          tupMatFormField
          tupMatRoundedSelect
          fxFlex
        >
          <mat-label>Campaign</mat-label>
          <mat-select value="test">
            <mat-option value="test">Test Campaign</mat-option>
            <mat-option value="test">Campaign #2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper fxFlex gap="16" overflow="auto">
    <mat-tab-group
      #ichabodsBody
      tupMatIchabodsBody
      id="tab-group"
      fxFlexv
      color="primary"
    >
      <mat-tab label="Graph View" class="full-height-tab">
        <div
          class="graph-view"
          fxLayout="row"
          fxLayoutGap="13px"
          fxLayout.lt-md="column"
        >
          <tup-mat-card fxLayout="column" fxFlex="100" fxFlex.lt-md="0">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="tup-info-text"
            >
              <h2 class="mat-h2" fxFlex>Men</h2>
              <button mat-icon-button>
                <mat-icon>settings_outline</mat-icon>
              </button>
            </div>
            <h4 class="mat-h4">Fruit</h4>
            <div [style.height.px]="fauxData1.length * 30">
              <ngx-charts-bar-horizontal
                [results]="fauxData1"
                [scheme]="scheme"
                xAxis="true"
                yAxis="true"
                xScaleMax="20"
              ></ngx-charts-bar-horizontal>
            </div>
          </tup-mat-card>
          <tup-mat-card fxLayout="column" fxFlex="100" fxFlex.lt-md="0">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="tup-info-text"
            >
              <h2 class="mat-h2" fxFlex>Men</h2>
              <button mat-icon-button>
                <mat-icon>settings_outline</mat-icon>
              </button>
            </div>
            <h4 class="mat-h4">Shoes</h4>
            <div [style.height.px]="fauxData2.length * 30">
              <ngx-charts-bar-horizontal
                [results]="fauxData2"
                [scheme]="scheme"
                xAxis="true"
                yAxis="true"
                xScaleMax="20"
              ></ngx-charts-bar-horizontal>
            </div>
          </tup-mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Table View">
        <div class="rounded-table-container">
          <tup-mat-card>
          <table mat-table [dataSource]="fauxTable" class="mat-elevation-z4">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Insights Title</th>
              <td mat-cell *matCellDef="let element" class="mat-body-2">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="labels">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="tup-info-text">
                  <p class="mat-body-1">Audience (000)</p>
                  <p class="mat-body-1">Respondents</p>
                  <p class="mat-body-1">% Row</p>
                  <p class="mat-body-1">% Col</p>
                  <p class="mat-body-1">Index</p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="totals">
              <th mat-header-cell *matHeaderCellDef>Totals</th>
              <td mat-cell *matCellDef="let element">
                <div class="tup-info-text">
                  <p *ngFor="let total of element.totals" class="mat-body-1">{{total}}</p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="men">
              <th mat-header-cell *matHeaderCellDef>Men</th>
              <td mat-cell *matCellDef="let element;let even = even;" [ngClass]="{'even': even,'odd': !even}">
                <div class="tup-info-text">
                  <p *ngFor="let value of element.men" class="mat-body-1">{{value}}</p>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          </tup-mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </tup-mat-content-wrapper>
</div>
