import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppRoutingModule } from './modules/app-routing.module';
import { AppMaterialModule } from './modules/app-material.module';
import { TupMaterialModule } from '@telmar-global/tup-material';


import { SidebarWrapperComponent } from './components/sidebar-wrapper/sidebar-wrapper.component';


import { Page1Component } from './pages/page1/page1.component';
import { Page2Component } from './pages/page2/page2.component';
import { Page3Component } from './pages/page3/page3.component';
import { Page4Component } from './pages/page4/page4.component';
import { Page5Component } from './pages/page5/page5.component';
import { Page6Component } from './pages/page6/page6.component';
import { ButtonsComponent } from './component_pages/buttons/buttons.component';
import { LayoutComponent } from './component_pages/layout/layout.component';
import { FormControlsComponent } from './component_pages/form-controls/form-controls.component';
import { TablesComponent } from './component_pages/tables/tables.component';
import { TabsComponent } from './component_pages/tabs/tabs.component';
import { TitleBarComponent } from './component_pages/title-bar/title-bar.component';
import { TypographyComponent } from './component_pages/typography/typography.component';
import { FakeAccountMenuComponent } from './components/fake-account-menu/fake-account-menu.component';
import { MenusComponent } from './component_pages/menus/menus.component';

@NgModule({
  declarations: [
    AppComponent,

    SidebarWrapperComponent,

    Page1Component,
    Page2Component,
    Page3Component,
    Page4Component,
    Page5Component,
    Page6Component,
    ButtonsComponent,
    LayoutComponent,
    FormControlsComponent,
    TablesComponent,
    TabsComponent,
    TitleBarComponent,
    TypographyComponent,
    FakeAccountMenuComponent,
    MenusComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    AppRoutingModule,
    AppMaterialModule,
    NgxChartsModule,

    FlexLayoutModule,

    TupMaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
