<div class="mat-paginator-outer-container">
  <div class="mat-paginator-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="mat-paginator-range-label">
      {{ _intl.getRangeLabel(pageIndex, pageSize, length) }}
    </div>

    <div class="mat-paginator-range-actions">
      <button mat-icon-button type="button"
              class="mat-paginator-navigation-first"
              (click)="firstPage()"
              [attr.aria-label]="_intl.firstPageLabel"
              [matTooltip]="_intl.firstPageLabel"
              [matTooltipDisabled]="_previousButtonsDisabled()"
              [matTooltipPosition]="'above'"
              [disabled]="_previousButtonsDisabled()"
              *ngIf="showFirstLastButtons">
        <mat-icon>first_page</mat-icon>
      </button>
      <button mat-icon-button type="button"
              class="mat-paginator-navigation-previous"
              (click)="previousPage()"
              [attr.aria-label]="_intl.previousPageLabel"
              [matTooltip]="_intl.previousPageLabel"
              [matTooltipDisabled]="_previousButtonsDisabled()"
              [matTooltipPosition]="'above'"
              [disabled]="_previousButtonsDisabled()">
        <mat-icon>navigate_before</mat-icon>
      </button>

      <button *ngFor="let page of pageLinks" mat-icon-button type="button"
              class="mat-paginator-navigation tup-mat-paginator-navigation"
              [ngClass]="'tup-mat-paginator-navigation-' + page"
              [color]="pageIndex === page ? 'primary': null"
              (click)="goTo(page)"
              [attr.aria-label]="'Go to page ' + (page + 1)"
              [matTooltip]="'Go to page ' + (page + 1)"
              [matTooltipPosition]="'above'">
        {{ page + 1 }}
      </button>

      <button mat-icon-button type="button"
              class="mat-paginator-navigation-next"
              (click)="nextPage()"
              [attr.aria-label]="_intl.nextPageLabel"
              [matTooltip]="_intl.nextPageLabel"
              [matTooltipDisabled]="_nextButtonsDisabled()"
              [matTooltipPosition]="'above'"
              [disabled]="_nextButtonsDisabled()">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button mat-icon-button type="button"
              class="mat-paginator-navigation-last"
              (click)="lastPage()"
              [attr.aria-label]="_intl.lastPageLabel"
              [matTooltip]="_intl.lastPageLabel"
              [matTooltipDisabled]="_nextButtonsDisabled()"
              [matTooltipPosition]="'above'"
              [disabled]="_nextButtonsDisabled()"
              *ngIf="showFirstLastButtons">
        <mat-icon>last_page</mat-icon>
      </button>
    </div>

    <div class="mat-paginator-page-size" *ngIf="!hidePageSize">
      <div class="mat-paginator-page-size-label">
        {{ _intl.itemsPerPageLabel }}
      </div>

      <mat-form-field
        tupMatFormField
        tupMatMiniSelectContainer
        *ngIf="_displayedPageSizeOptions.length > 1"
        [appearance]="formFieldAppearance!"
        [color]="color"
        class="mat-paginator-page-size-select">
        <mat-select
          [value]="pageSize"
          [disabled]="disabled"
          [aria-label]="_intl.itemsPerPageLabel"
          (selectionChange)="changePageSize($event.value)">
          <mat-option *ngFor="let pageSizeOption of _displayedPageSizeOptions" [value]="pageSizeOption">
            {{ pageSizeOption }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mat-paginator-page-size-value" *ngIf="_displayedPageSizeOptions.length <= 1">{{ pageSize }}</div>
    </div>
  </div>
</div>
