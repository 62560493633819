<div fxFill fxLayout="column">
  <tup-mat-content-wrapper position="first">
    <tup-mat-title-bar
      title="Market Selection"
      subtitle="Your primary market"
    ></tup-mat-title-bar>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last">
    <div
      fxLayout="row"
      fxLayoutGap="13px"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start"
    >
      <div fxLayout="row" fxFlex fxLayoutAlign="start end">
        <tup-mat-ichabods-tabs [ichabodsBody]="ichabodsBody"></tup-mat-ichabods-tabs>
      </div>
      <div fxLayout="row" fxLayoutGap="13px" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" tupMatButton appearance="large">
          Save
        </button>
        <button mat-flat-button color="primary" tupMatButton appearance="large">
          Save As...
        </button>
      </div>
    </div>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper fxFlex gap="16" overflow="auto">
    <mat-tab-group
      #ichabodsBody
      tupMatIchabodsBody
      id="tab-group"
      fxFlexv
      color="primary"
    >
      <mat-tab label="Markets" class="full-height-tab">
        <tup-mat-content-wrapper gap="16" fxLayout="column" fxLayoutGap="16px">
          <tup-mat-card>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayout.lt-md="column"
              fxLayoutAlign="start center"
              fxLayoutAlign.lt-md="start"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Market Name</mat-label>
                <input matInput />
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Short Report Type</mat-label>
                <mat-select value="0">
                  <mat-option value="0">PPM</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Long Report Type</mat-label>
                <mat-select value="0">
                  <mat-option value="0">PPM Standard</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Survey</mat-label>
                <mat-select value="0">
                  <mat-option value="0">July 2020</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </tup-mat-card>

          <tup-mat-card>
            <div fxLayout="column">
              <h4 class="mat-h4">Selected</h4>
              <mat-chip-list #chipList color="primary">
                <mat-chip removable="true"
                  >Atlanta
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip removable="true"
                  >Boston
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </tup-mat-card>

          <h4 class="mat-h4">Markets</h4>
        </tup-mat-content-wrapper>
        <tup-mat-content-wrapper fxFlex gap="16" fxLayout="column" position="last">
          <tup-mat-card>
            <div class="table-wrapper">
              <table mat-table [dataSource]="dataSource1">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Market Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="geography">
                  <th mat-header-cell *matHeaderCellDef>Geography</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.geography }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="sample">
                  <th mat-header-cell *matHeaderCellDef>Sample</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.sample }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="population">
                  <th mat-header-cell *matHeaderCellDef>Population [00]</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.population }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </tup-mat-card>
        </tup-mat-content-wrapper>
      </mat-tab>
      <mat-tab label="Targets" class="full-height-tab"> </mat-tab>
      <mat-tab label="Station Selection" class="full-height-tab"> </mat-tab>
      <mat-tab label="Planning" class="full-height-tab"> </mat-tab>
    </mat-tab-group>
  </tup-mat-content-wrapper>
</div>
