<tup-mat-content-wrapper  gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33" position="first">

    <tup-mat-title-bar title="Title" subtitle="Subtitle">
      <div>
        Some content in title bar
      </div>
    </tup-mat-title-bar>

  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="middle"  gap="33" overflow="auto">

    <tup-mat-card style="margin: 16px;">
      <h3>TUP Mat Card</h3>
      <div>
        A content
        <p>
          inside the card
        </p>
      </div>
    </tup-mat-card>

  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper >
