<mat-toolbar class="tup-mat-topbar">

    <button class="menu-button" [fxShow]="hasSidebar" mat-icon-button tupMatButton (click)="onToggle()">
      <mat-icon [@openSidebar]="state">menu</mat-icon>
    </button>

    <tup-mat-logo (logoClick)="onLogoClick()"></tup-mat-logo>

    <span fxFlex></span>

    <ng-content></ng-content>

</mat-toolbar>
