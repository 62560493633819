import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page3',
  templateUrl: './page3.component.html',
  styleUrls: ['./page3.component.scss']
}) 
export class Page3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
