<div style="height: 100%">
  <mat-drawer-container class="tup-mat-rightsidebar" fxFlex #sidenavContainer>
    <mat-drawer #rightsidenav
      (openedStart)="onOpenedChange( true )"
      (closedStart)="onOpenedChange( false )"
      [opened]="opened"
      [mode]="mode"
      position="end"
    >

      <div fxLayout="column" class="container" fxFlex>
          <!-- close button -->
          <div *ngIf="closeButton" fxLayout="row" fxLayoutAlign="center" class="close-button">
              <button mat-stroked-button color="primary" tupMatButton appearance="nano" (click)="onOpenedChange(false)"><mat-icon class="close-button-icon">close</mat-icon></button>
          </div>

          <!-- Search -->
          <div *ngIf="searchableItems?.length > 0">
            <mat-form-field class="search-field" appearance="outline" tupMatFormField>
              <mat-icon matPrefix>search</mat-icon>
              <mat-label>Search</mat-label>
              <input matInput [(ngModel)]="searchItem" (keypress)="onKeyPress($event)" (input)="onSearch(menuContainer)" />
            </mat-form-field>

            <mat-divider class="spacer"></mat-divider>
          </div>

          <!-- Menu -->
          <div class="menu-container" #menuContainer fxFlex>
            <div *ngFor="let menuItem of menuItems" [id]="menuItem.id" [class]="'main-menu-item collapsed ' + menuItem.cssClass" #mainMenuItem>
              <div fxLayout="row" fxLayoutAlign="start center" #menuTitle>
                <button class="menu-title" mat-button fxFlex (click)="toggleMenuExpand(mainMenuItem, menuItem)">
                  <mat-icon *ngIf="menuItem.matIconName" class="mat-icon-left">{{menuItem.matIconName}}</mat-icon>
                  <span>{{menuItem.menuName}}</span>
                  <mat-icon class="mat-icon-right" *ngIf="hasContent(menuTitle, menuItem)">keyboard_arrow_down</mat-icon>
                </button>
              </div>
              <div class="main-menu-content" #menuContent>
                <ng-template [ngTemplateOutlet]="menuItem.template"></ng-template>
              </div>
            </div>
          </div>

      </div>
    </mat-drawer>

    <mat-drawer-content class="mat-app-background" fxLayout="column">

      <!-- the main client window content -->
      <div class="main-content" fxFlex>
          <ng-content></ng-content>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>
