<tup-mat-content-wrapper gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33" overflow="visible">
    <h2>
      Tabs
      <span fxFlex></span>
      <ng-container [ngTemplateOutlet]="tabSwitcher"></ng-container>
    </h2>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="middle" gap="33" overflow="visible" fxLayout="column">
    <h3>Ichabods Tabs</h3>
    <div fxLayout="row" fxLayoutGap="1rem">
      <div fxLayout="column">
        <h4>Default header</h4>
        <div fxLayout="row" fxLayoutAlign="start center">
          <tup-mat-ichabods-tabs #ichabodsTabs [ichabodsBody]="ichabodsBody"></tup-mat-ichabods-tabs>
        </div>
        <mat-tab-group #ichabodsBody tupMatIchabodsBody fxFlex color="primary">
          <mat-tab label="Regular Tab (Label)">
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <ng-container [ngTemplateOutlet]="tabTwoLabel"></ng-container>
            </ng-template>
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div fxLayout="column">
        <h4>Full width header</h4>
        <div fxLayout="row" fxLayoutAlign="start center">
          <tup-mat-ichabods-tabs #ichabodsTabsFullWidth [ichabodsBody]="ichabodsBodyFullWidth"
            class="full-width-header"></tup-mat-ichabods-tabs>
        </div>
        <mat-tab-group #ichabodsBodyFullWidth tupMatIchabodsBody fxFlex color="primary">
          <mat-tab label="Regular Tab (Label)">
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <ng-container [ngTemplateOutlet]="tabTwoLabel"></ng-container>
            </ng-template>
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div fxLayout="column">
      <h4>Highlight previous tabs</h4>

      <div fxLayout="row" fxLayoutAlign="start center">
        <tup-mat-ichabods-tabs #ichabodsTabsHighlightPrevious [ichabodsBody]="ichabodsBodyHighlightPrevious"
          class="full-width-header highlight-previous-tabs"></tup-mat-ichabods-tabs>
      </div>
      <mat-tab-group #ichabodsBodyHighlightPrevious tupMatIchabodsBody fxFlex color="primary">
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="middle" gap="33" overflow="visible">
    <h3>Material Tabs</h3>
    <div fxLayout="row" fxLayoutGap="1rem">
      <div fxLayout="column">
        <h4>Default header</h4>
        <mat-tab-group #standardTabs>
          <mat-tab label="Regular Tab (Label)">
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <ng-container [ngTemplateOutlet]="tabTwoLabel"></ng-container>
            </ng-template>
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div fxLayout="column">
        <h4>Full width header</h4>
        <mat-tab-group class="full-width-header" #standardTabsFullWidth>
          <mat-tab label="Regular Tab (Label)">
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <ng-container [ngTemplateOutlet]="tabTwoLabel"></ng-container>
            </ng-template>
            <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div fxLayout="column">
      <h4>Highlight previous tabs</h4>
      <mat-tab-group class="full-width-header highlight-previous-tabs" #standardTabsHighlightPrevious>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
        <mat-tab label="Regular Tab (Label)">
          <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>

<!-- Templates, which don't directly affect the functionality of the tabs -->
<ng-template #tabContent>
  <div style="width: 100%; height: 150px; background-color: #d5d5d5; border: 1px solid red;">
    Content
  </div>
  <tup-mat-card>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc dictum ullamcorper nisi, sed hendrerit orci tempus
      ac. Pellentesque finibus lectus in finibus finibus. Proin sagittis venenatis lectus, nec dictum ante. Fusce
      aliquet at dolor ac hendrerit. Donec eu tellus libero. Proin placerat tortor eget nulla condimentum, a ullamcorper
      ipsum tincidunt. Donec a pulvinar turpis. Praesent lacinia arcu in ultricies tempor. Fusce sagittis orci odio, vel
      accumsan ex bibendum ac. Vestibulum id orci erat. Vestibulum et enim sed justo vulputate vehicula sed nec massa.
      Cras condimentum eget erat ut malesuada.
    </p>
    <p>
      Morbi tempor est quis mi dictum, quis ullamcorper erat suscipit. Vivamus lacinia tincidunt libero, consequat
      aliquam nunc tincidunt a. Proin eu metus risus. Nullam justo risus, sagittis porta ex nec, pharetra convallis sem.
      Vivamus sed nunc diam. Nunc nec ultrices nibh. Pellentesque vel magna sed lorem vulputate blandit.
    </p>
  </tup-mat-card>
</ng-template>

<ng-template #tabTwoLabel>
  <mat-icon>thumb_up</mat-icon>
  &nbsp;&nbsp;
  Custom Tab
  &nbsp;&nbsp;
  <span>150</span>
</ng-template>

<ng-template #tabSwitcher>
  <button mat-flat-button tupMatButton appearance="small" color="primary" (click)="switchTabs()">
    <mat-icon>add_circle_outline</mat-icon> Switch tabs
  </button>
</ng-template>
