<div fxFill fxLayout="column">
  <tup-mat-content-wrapper position="first">
    <tup-mat-title-bar
      title="Inventory Detail | Persons 0+ yrs"
      subtitle="Measures Release: 2021r1x"
    ></tup-mat-title-bar>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last">
    <div
      fxLayout="row"
      fxLayoutGap="13px"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start"
    >
      <div fxLayout="row" fxFlex fxLayoutAlign="start end">
        <tup-mat-ichabods-tabs [ichabodsBody]="ichabodsBody"></tup-mat-ichabods-tabs>
      </div>
      <div fxLayout="row" fxLayoutGap="13px" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" tupMatButton appearance="large">
          Save
        </button>
        <button mat-flat-button color="primary" tupMatButton appearance="large">
          Save As...
        </button>
      </div>
    </div>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper fxFlex gap="16" overflow="auto">
    <mat-tab-group
      #ichabodsBody
      tupMatIchabodsBody
      id="tab-group"
      fxFlexv
      color="primary"
    >
      <mat-tab label="Geopath Search" class="full-height-tab">
        <tup-mat-content-wrapper gap="16" fxFill fxLayout="column" fxLayoutGap="16px">
          <h4 class="mat-h4">Target</h4>
          <tup-mat-card>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayout.lt-md="column"
              fxLayoutAlign="start center"
              fxLayoutAlign.lt-md="start"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Base Segment</mat-label>
                <mat-select value="0">
                  <mat-option value="0">Persons 0+ yrs</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Target Segment</mat-label>
                <mat-select value="0">
                  <mat-option value="0">Persons 0+ yrs</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Target Geographies</mat-label>
                <mat-select value="0">
                  <mat-option value="0">Any</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </tup-mat-card>
          <h4 class="mat-h4">Inventory</h4>
          <tup-mat-card>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayout.lt-md="column"
              fxLayoutAlign="start center"
              fxLayoutAlign.lt-md="start"
            >
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Inventory Locations</mat-label>
                <mat-select value="0">
                  <mat-option value="0">Zipcode 17554</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Operators</mat-label>
                <mat-select value="0">
                  <mat-option value="0"
                    >That one guy who sells billboards</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <button
                mat-stroked-button
                tupMatButton
                color="primary"
                matBadge="3"
                matBadgePosition="after"
                matBadgeColor="primary"
              >
                More <mat-icon>expand_more</mat-icon>
              </button>
            </div>
          </tup-mat-card>
          <div fxLayout="row" fxLayoutAlign="start center" class="tup-info-text">
            <h4 class="mat-h4" fxFlex>Measures</h4>
            <button mat-flat-button color="primary" tupMatButton appearance="large">
              <mat-icon>add_circle_outline</mat-icon> Add Measure
            </button>
          </div>
          <tup-mat-card
            fxLayout="row"
            fxLayoutGap="13px"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center"
            fxLayoutAlign.lt-md="start"
          >
            <mat-form-field
              appearance="outline"
              tupMatFormField
              fxFlex
            >
              <mat-label>Type</mat-label>
              <mat-select value="0">
                <mat-option value="0">None</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" tupMatFormField>
              <mat-label>Max</mat-label>
              <input matInput type="number" />
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
              <mat-form-field
                appearance="outline"
                tupMatFormField
                fxFlex
              >
                <mat-label>Min</mat-label>
                <input matInput type="number" />
              </mat-form-field>
              <button mat-icon-button><mat-icon>clear</mat-icon></button>
            </div>
          </tup-mat-card>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button color="primary" tupMatButton appearance="large">
              Search
            </button>
          </div>
        </tup-mat-content-wrapper>
      </mat-tab>
      <mat-tab label="Add by ID" class="full-height-tab"> </mat-tab>
      <mat-tab label="Quick Search" class="full-height-tab"> </mat-tab>
      <mat-tab label="Map" class="full-height-tab"> </mat-tab>
      <mat-tab label="Summary" class="full-height-tab"> </mat-tab>
    </mat-tab-group>
  </tup-mat-content-wrapper>
</div>
