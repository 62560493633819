<tup-mat-content-wrapper  gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33">
    <h2>Form Controls</h2>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle"  gap="33" overflow="auto">

    <tup-mat-card>
      <h3 class="mat-h4">Outlined form field (TUP Material)</h3>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field appearance="outline" tupMatFormField>
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          [color]="'warn'"
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field appearance="outline" tupMatFormField [color]="'warn'">
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
          [color]="'warn'"
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Outlined form field (Angular Material)</h3>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          appearance="outline"
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          appearance="outline"
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Standard form field (TUP Material)</h3>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          tupMatFormField
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field tupMatFormField>
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          tupMatFormField
          fxFlex
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          tupMatFormField
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Market Name</mat-label>
          <input matInput />
        </mat-form-field>

        <mat-form-field tupMatFormField>
          <mat-label>Max</mat-label>
          <input matInput type="number" />
        </mat-form-field>

        <mat-form-field
          tupMatFormField
          fxFlex
        >
          <mat-label>Target Geographies</mat-label>
          <mat-select value="0">
            <mat-option value="0">Any</mat-option>
            <mat-option value="1">Far, far away</mat-option>
            <mat-option value="2">Middle-earth</mat-option>
            <mat-option value="3">Narnia</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Form field error messages</h3>
      <div
        fxLayout="column"
        fxLayoutGap="16px"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <tup-mat-card>
          <h4>TUP Material</h4>
          <div
            fxLayout="row"
            fxLayoutGap="16px"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center"
            fxLayoutAlign.lt-md="start"
          >
            <mat-form-field tupMatFormField appearance="outline">
              <mat-label>Enter your email</mat-label>
              <input matInput placeholder="pat@example.com" [formControl]="email" required>
            </mat-form-field>
          </div>
        </tup-mat-card>


        <tup-mat-card>
          <h4>Angular Material</h4>
          <div
            fxLayout="row"
            fxLayoutGap="16px"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center"
            fxLayoutAlign.lt-md="start"
          >
            <mat-form-field appearance="fill">
              <mat-label>Enter your email</mat-label>
              <input matInput placeholder="pat@example.com" [formControl]="email" required>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
        </tup-mat-card>

      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Select (TUP Material)</h3>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-card style="background-color: rgb(229, 229, 229);">
          <mat-form-field
            appearance="fill"
            tupMatRoundedSelect
          >
            <mat-label>Insight</mat-label>
            <mat-select value="All">
              <mat-option value="All">All</mat-option>
              <mat-option value="Men">Men</mat-option>
              <mat-option value="Women">Women</mat-option>
            </mat-select>
          </mat-form-field>

        </mat-card>

        <mat-form-field
          appearance="fill"
          tupMatRoundedSelect
        >
          <mat-label>Insight</mat-label>
          <mat-select value="All">
            <mat-option value="All">All</mat-option>
            <mat-option value="Men">Men</mat-option>
            <mat-option value="Women">Women</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Select (Angular Material)</h3>
      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-form-field
          appearance="fill"
        >
          <mat-label>Insight</mat-label>
          <mat-select value="All">
            <mat-option value="All">All</mat-option>
            <mat-option value="Men">Men</mat-option>
            <mat-option value="Women">Women</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Chip lists</h3>

      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

      <mat-form-field tupMatFormField appearance="outline" fxFlex>
        <mat-label>Chips</mat-label>

        <mat-chip-list #chipList>
          <mat-basic-chip>Chip 1</mat-basic-chip>
          <mat-basic-chip [color]="'accent'">Chip 2</mat-basic-chip>
          <mat-basic-chip [color]="'warn'">Chip 3</mat-basic-chip>
        </mat-chip-list>

        <button mat-icon-button matSuffix>
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field tupMatFormField appearance="outline" fxFlex [color]="'warn'">
        <mat-label>Removable chips</mat-label>

        <mat-chip-list #chipList>
          <mat-chip fxLayout="row">
            <div fxFlex>Chip 1</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip fxLayout="row" [color]="'accent'">
            <div fxFlex>Chip 2</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip fxLayout="row" [color]="'warn'">
            <div fxFlex>Chip 3</div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <button mat-icon-button matSuffix>
          <mat-icon>add</mat-icon>
        </button>
      </mat-form-field>

      <!-- Demonstrates this bug: https://github.com/angular/components/issues/20197 -->
      <mat-form-field appearance="outline" tupMatFormField fxFlex>
        <mat-label>matChipInputFor</mat-label>
        <input matInput [matChipInputFor]="savedPlacesSearch">
        <mat-chip-list #savedPlacesSearch style="display: none;">
        </mat-chip-list>
      </mat-form-field>

    </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Checkboxes (Angular Material)</h3>

      <div
        fxLayout="row"
        fxLayoutGap="16px"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="start"
      >

        <mat-checkbox>Accent (default)</mat-checkbox>
        <mat-checkbox color="primary">Primary</mat-checkbox>
        <mat-checkbox [disabled]="true">Disabled</mat-checkbox>

      </div>
    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Small checkboxes (TUP Material)</h3>

        <div
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayout.lt-md="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start"
        >

          <mat-checkbox tupMatCheckbox appearance="small">Accent (default)</mat-checkbox>
          <mat-checkbox tupMatCheckbox appearance="small" color="primary">Primary</mat-checkbox>
          <mat-checkbox tupMatCheckbox appearance="small" [disabled]="true">Disabled</mat-checkbox>

        </div>

    </tup-mat-card>

    <tup-mat-card>
      <h3 class="mat-h4">Medium checkboxes (TUP Material)</h3>

        <div
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayout.lt-md="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start"
        >

          <mat-checkbox tupMatCheckbox>Accent (default)</mat-checkbox>
          <mat-checkbox tupMatCheckbox color="primary">Primary</mat-checkbox>
          <mat-checkbox tupMatCheckbox [disabled]="true">Disabled</mat-checkbox>

        </div>

      </tup-mat-card>

      <tup-mat-card>
        <h3 class="mat-h4">Large checkboxes (TUP Material)</h3>

        <div
          fxLayout="row"
          fxLayoutGap="16px"
          fxLayout.lt-md="column"
          fxLayoutAlign="start center"
          fxLayoutAlign.lt-md="start"
        >

          <mat-checkbox tupMatCheckbox appearance="large">Accent (default)</mat-checkbox>
          <mat-checkbox tupMatCheckbox appearance="large" color="primary">Primary</mat-checkbox>
          <mat-checkbox tupMatCheckbox appearance="large" [disabled]="true">Disabled</mat-checkbox>

        </div>
    </tup-mat-card>

  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper >
