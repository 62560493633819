import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { IchabodsTabsComponent } from '@telmar-global/tup-material';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @ViewChild('ichabodsTabs') ichabodsTabs: IchabodsTabsComponent;
  @ViewChild('ichabodsTabsFullWidth') ichabodsTabsFullWidth: IchabodsTabsComponent;
  @ViewChild('ichabodsTabsHighlightPrevious') ichabodsTabsHighlightPrevious: IchabodsTabsComponent;
  @ViewChild('standardTabs') standardTabs: MatTabGroup;
  @ViewChild('standardTabsFullWidth') standardTabsFullWidth: MatTabGroup;
  @ViewChild('standardTabsHighlightPrevious') standardTabsHighlightPrevious: MatTabGroup;

  constructor() {}

  ngOnInit(): void {}

  switchTabs() {
    let activeTabIndex = 0;

    if (this.ichabodsTabs.activeTabIndex == 0) activeTabIndex = 1;

    this.ichabodsTabs.activeTabIndex =
      this.ichabodsTabsFullWidth.activeTabIndex =
      this.ichabodsTabsHighlightPrevious.activeTabIndex =
      this.standardTabs.selectedIndex =
      this.standardTabsFullWidth.selectedIndex =
      this.standardTabsHighlightPrevious.selectedIndex =
        activeTabIndex;
  }
}
