import { Component, OnInit } from '@angular/core';

interface FakeDoc {
  name: string;
  audience: number;
  responses: number;
  percent: number;
  createdBy: string;
  createdOn: string;
  status: string;
  selected: boolean;
}

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {
  displayedColumns_1: string[] = ['selected', 'name', 'audience', 'responses', 'percent','createdBy','createdOn','status'];
  dataSource_1: FakeDoc[] = [
    { name: "Lorem Ipsum", audience: 12405, responses: 16700, percent: 0.5, createdBy: "John", createdOn: "2021/07/02", status: "", selected: false },
    { name: "Donec in nisi vel elit", audience: 6, responses: 2000, percent: 10, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Nulla elementum lorem", audience: 234, responses: 567, percent: 0.5, createdBy: "John", createdOn: "2021/07/02", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "", selected: false }
  ];

  displayedColumns_2: string[] = ['name', 'labels', 'totals', 'men'];
  dataSource_2: any[] = ["Total", "Oranges", "Peaches", "Apples"].map((title: string) => {
    return { name: title, totals: [250975, Math.floor(Math.random() * 370000), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2)], men: [250975, Math.floor(Math.random() * 370000), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2)] }
  });

  constructor() { }

  ngOnInit(): void {
  }

}
