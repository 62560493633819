import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
  //Lists possible size variations of buttons available
  sizes = ['large', 'medium', 'small'];

  //Lists types of buttons each variation will display
  buttonTypes = [new ButtonType(), new ButtonType(true), new ButtonType(false, true)];

  //Lists all styles of buttons available
  columnsToDisplay = [
    'PrimaryFlat',
    'PrimaryStroked',
    'AccentFlat',
    'AccentStroked',
    'WarnFlat',
    'WarnStroked',

    //For future expansion of examples to match designs
    // 'IconLeft',
    // 'IconRight',
    // 'IconTextIcon',
    // 'DoubleIcon',
    // 'SingleIcon',
  ];

  constructor() {}

  ngOnInit(): void {}
}

export class ButtonType {
  constructor(public isDisabled: boolean = false, public isIconButton = false) {}
}
