<tup-mat-content-wrapper gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33">
    <h2>Typography</h2>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle" gap="33" overflow="auto">
    <tup-mat-card fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
      <p>
        The following styles can be added by including a mixin:<br/>
        @include mat-typography-level-to-styles($tup-mat-typography-config, "caption");
      </p>
      <div class="mat-display-4">Material display-4</div>
      <div class="mat-display-3">Material display-3/Figma title 1</div>
      <div class="mat-display-2">Material display-2/Figma title 2</div>
      <div class="mat-display-1">Material display-1/Figma title 3</div>
      <div class="mat-headline">Material headline/Figma headline</div>
      <div class="mat-title">Material title/Figma subheadline</div>
      <div class="mat-subheading-2">Material subheading-2</div>
      <div class="mat-subheading-1">Material subheading-1</div>
      <div class="mat-h5">Material h5</div>
      <div class="mat-h6">Material h6</div>
      <div class="mat-body">Material body-1/Figma body small</div>
      <div class="mat-body-2">Material body-2/Figma body small bold</div>
      <div class="mat-body-3">TUP Material body-3/Figma body</div>
      <div class="mat-body-4">TUP Material body-4/Figma body bold</div>
      <div class="mat-small">Material caption/Figma caption</div>
    </tup-mat-card>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>
