import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page1Component } from '../pages/page1/page1.component';
import { Page2Component } from '../pages/page2/page2.component';
import { Page3Component } from '../pages/page3/page3.component';
import { Page4Component } from '../pages/page4/page4.component';
import { Page5Component } from '../pages/page5/page5.component';
import { Page6Component } from '../pages/page6/page6.component';
import { ButtonsComponent } from '../component_pages/buttons/buttons.component';
import { TypographyComponent } from '../component_pages/typography/typography.component';
import { FormControlsComponent } from '../component_pages/form-controls/form-controls.component';
import { LayoutComponent } from '../component_pages/layout/layout.component';
import { TablesComponent } from '../component_pages/tables/tables.component';
import { TabsComponent } from '../component_pages/tabs/tabs.component';
import { TitleBarComponent } from '../component_pages/title-bar/title-bar.component';
import { MenusComponent } from '../component_pages/menus/menus.component';

const routes: Routes = [
  { path: '', redirectTo: 'page1', pathMatch: 'full' },
  { path: 'page1', component: Page1Component},
  { path: 'page2', component: Page2Component},
  { path: 'page3', component: Page3Component},
  { path: 'page4', component: Page4Component},
  { path: 'page5', component: Page5Component},
  { path: 'page6', component: Page6Component},

  { path: 'page_buttons', component: ButtonsComponent},
  { path: 'page_layout', component: LayoutComponent},
  { path: 'page_form_controls', component: FormControlsComponent},
  { path: 'page_tables', component: TablesComponent},
  { path: 'page_tabs', component: TabsComponent},
  { path: 'page_title_bar', component: TitleBarComponent},
  { path: 'page_typography', component: TypographyComponent},
  { path: 'menus', component: MenusComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
