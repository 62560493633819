<div fxFill fxLayout="column">
  <tup-mat-content-wrapper position="first">
    <tup-mat-title-bar title="My insights dashboard"></tup-mat-title-bar>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="first">
    <div fxLayout="row" fxLayoutAlign="start center">
      <tup-mat-ichabods-tabs [ichabodsBody]="ichabodsBody"></tup-mat-ichabods-tabs>
      <span fxFlex></span>
      <button mat-flat-button tupMatButton appearance="large" color="primary">
        <mat-icon>add_circle_outline</mat-icon> Create new insight
      </button>
    </div>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper overflow="auto" fxFlex gap="16">
    <mat-tab-group
      #ichabodsBody
      tupMatIchabodsBody
      fxFlex
      color="primary"
    >
      <mat-tab label="Active Insights">
        <div fxLayout="column" class="table-wrapper">
          <table mat-table [dataSource]="dataSource1" class="mat-elevation-z3">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Campaign Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="audience">
              <th mat-header-cell *matHeaderCellDef>Audience</th>
              <td mat-cell *matCellDef="let element">{{ element.audience }}</td>
            </ng-container>

            <ng-container matColumnDef="responses">
              <th mat-header-cell *matHeaderCellDef>Responses</th>
              <td mat-cell *matCellDef="let element">
                {{ element.responses }}
              </td>
            </ng-container>

            <ng-container matColumnDef="percent">
              <th mat-header-cell *matHeaderCellDef>%</th>
              <td mat-cell *matCellDef="let element">{{ element.percent }}</td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>Created By</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdBy }}
              </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
              <th mat-header-cell *matHeaderCellDef>Created On</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdOn }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-icon>more_vert</mat-icon>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Archived"> -->
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>thumb_up</mat-icon>
          &nbsp;&nbsp;
          Archived
          &nbsp;&nbsp;
          <span>150</span>
        </ng-template>

        <div fxLayout="column" class="table-wrapper">
          <table mat-table [dataSource]="dataSource2" class="mat-elevation-z3">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Campaign Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="audience">
              <th mat-header-cell *matHeaderCellDef>Audience</th>
              <td mat-cell *matCellDef="let element">{{ element.audience }}</td>
            </ng-container>

            <ng-container matColumnDef="responses">
              <th mat-header-cell *matHeaderCellDef>Responses</th>
              <td mat-cell *matCellDef="let element">
                {{ element.responses }}
              </td>
            </ng-container>

            <ng-container matColumnDef="percent">
              <th mat-header-cell *matHeaderCellDef>%</th>
              <td mat-cell *matCellDef="let element">{{ element.percent }}</td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>Created By</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdBy }}
              </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
              <th mat-header-cell *matHeaderCellDef>Created On</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdOn }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">{{ element.status }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </tup-mat-content-wrapper>
</div>
