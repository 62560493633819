<div #menuButtonContainer
  [class.sidebar-visible]="hasSidebar"
  [class.sidebar-reduced]="!sidebarOpenState"
  [class.mouseover]="navMouseOver || buttonMouseOver">
  <button *ngIf="hasSidebar && !hasTopbar" class="menu-button" mat-stroked-button color="primary" appearance="nano" tupMatButton (click)="onToggle()"
  (mouseover)="onButtonMouseOver($event)" (mouseout)="onButtonMouseOut($event)">
    <mat-icon [@openSidebar]="state">chevron_right</mat-icon>
  </button>
</div>

<div fxFill fxLayout="column">
  <mat-drawer-container class="tup-mat-sidebar" fxFlex #sidenavContainer
    [class.sidebar-visible]="hasSidebar"
    [class.sidebar-reduced]="!sidebarOpenState">

      <!-- oringinal attribute: [mode]= isMobile ? 'over' : 'side' -->
      <mat-drawer
        (openedStart)="onOpenedChange(true)"
        (closedStart)="onOpenedChange(false)"
        [opened]="hasSidebar ? sidebarOpenState : false"
        #sidenav
        [mode]="sidebarMode"
        (click)="isMobile ? sidenav.close() : ''"
        (mouseover)="onNavMouseOver($event)" (mouseout)="onNavMouseOut($event)"
        (touchstart)="onTouchStart($event)"
      >
      <div fxLayout="column" fxFill>
        <div class="drawer-inner-wrapper accordion-wrapper" [class.has-top-bar]="hasTopbar" fxLayout="column" fxFlex>
          <div *ngIf="!hasTopbar" class="tup-mat-sidebar-header" fxLayout="column">
            <div class="logo-container">
              <tup-mat-logo (logoClick)="onLogoClick()"></tup-mat-logo>
            </div>


            <span fxFlex></span>
          </div>
          <ng-content select="tup-mat-sidebar-content"></ng-content>
          <ng-content select="tup-mat-sidebar-footer-content"></ng-content>
        </div>
        <tup-mat-sidebar-footer [appVersion]="appVersion" [sidebarOpenState]="sidebarOpenState"></tup-mat-sidebar-footer>

      </div>
    </mat-drawer>

    <mat-drawer-content class="mat-app-background" fxLayout="column"
      [class.over]="sidenav.mode === 'over'">

      <div *ngIf="!hasTopbar && hasHiddenTopbar" class="hidden-top-bar">
        <tup-mat-topbar-content>
          <ng-content select="tup-mat-topbar-content"></ng-content>
        </tup-mat-topbar-content>
      </div>

      <div class="main-content" [class.has-top-bar]="hasTopbar" fxFlex>
        <ng-content></ng-content>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>

