<tup-mat-furniture
      appVersion="1.2.3.4"
      [sidebarOpenState]="false"
      sidebarMode="side"
      [hasSidebar]="hasSidebar"
      (logoClick)="onLogoClick()"
      (toggleSidePanel)="onToggleSideBar($event)"
      (toggleRigthSidePanel)="onToggleRightSideBar($event)"
      [hasTopbar]="oldFashion"
      [hasHiddenTopbar]="hasHiddenTopbar"
      >

  <tup-mat-topbar-content class="hidden-top-bar">
    <!-- in real life, use mat-tab-nav-bar and mat-tab-nav-panel instead -->
    <div class="hidden-toolbar-content" fxLayout="row">

      <nav mat-tab-nav-bar fxFlex>
        <a mat-tab-link routerLink="/page1" routerLinkActive="active" #rla0="routerLinkActive" [active]="rla0.isActive">Insights</a>
        <a mat-tab-link routerLink="/page_buttons" routerLinkActive="active" #rla1="routerLinkActive" [active]="rla1.isActive">Buttons</a>
        <a mat-tab-link routerLink="/page_layout" routerLinkActive="active" #rla2="routerLinkActive" [active]="rla2.isActive">Layout</a>
        <a mat-tab-link routerLink="/page_form_controls" routerLinkActive="active" #rla3="routerLinkActive" [active]="rla3.isActive">Form controls</a>
        <a mat-tab-link routerLink="/page_tables" routerLinkActive="active" #rla4="routerLinkActive" [active]="rla4.isActive">Tables</a>
        <a mat-tab-link routerLink="/page_tabs" routerLinkActive="active" #rla5="routerLinkActive" [active]="rla5.isActive">Tabs</a>
        <a mat-tab-link routerLink="/page_title_bar" routerLinkActive="active" #rla6="routerLinkActive" [active]="rla6.isActive">Title bar</a>
        <a mat-tab-link routerLink="/page_typography" routerLinkActive="active" #rla7="routerLinkActive" [active]="rla7.isActive">Typography</a>
        <a mat-tab-link routerLink="/menus" routerLinkActive="active" #rla8="routerLinkActive" [active]="rla8.isActive">Menus</a>
      </nav>

      <div fxLayout="row" fxLayoutGap="10px" fxFlexAlign="center">
        <b class="radio-button-label">Additional Label</b>

        <!-- button toggles -->
        <mat-button-toggle-group [(ngModel)]="dataOrGraph">
          <mat-button-toggle [value]="true">Data</mat-button-toggle>
          <mat-button-toggle [value]="false">Charts</mat-button-toggle>
        </mat-button-toggle-group>  

        <!-- option buttons -->
        <button mat-stroked-button color="primary" tupMatButton appearance="tiny"><mat-icon>file_download</mat-icon></button>
        <button mat-stroked-button color="primary" tupMatButton appearance="tiny"><mat-icon>save</mat-icon></button>

        <!-- right-sidebar trigger, positioned (badly)  -->
        <!-- rightsidebar-button uses appearance nano size so padding-top needed to center? -->
        <div style="padding-top: 2px;">
          <tup-mat-rightsidebar-button [(opened)]="rightSidebarOpened" ></tup-mat-rightsidebar-button>
        </div>

      </div>

    </div>
  </tup-mat-topbar-content>

  <!-- Content to be added to the topbar -->
  <tup-mat-topbar-content>

    <div fxLayout="row" fxLayoutAlign="end center" >

      <h6 class="component-label"> &lt;tup-mat-furniture&gt; </h6>

      <button mat-icon-button style="z-index: 99" >
        <mat-icon>menu</mat-icon>
      </button>

      <span fxFlex></span>

      <button mat-icon-button><mat-icon>storage</mat-icon></button>
      <button mat-icon-button><mat-icon>help_outline</mat-icon></button>
      <button mat-icon-button>
        <mat-icon matBadge="2" matBadgeColor="warn">notifications</mat-icon>
      </button>
    </div>
  </tup-mat-topbar-content>

  <!-- Add sidebar content -->
  <tup-mat-sidebar-content fxFlex>

    <!-- Insights side bar content exemple-->
    <div class="sidebar-content" fxLayout="column" fxLayoutGap="15px">
      <div class="sidebar-button">
        <button [routerLink]="'/page1'" mat-button data-qa="dashboard-btn">
          <mat-icon>bar_chart</mat-icon><span *ngIf="sidebarOpened">Insights</span>
        </button>
      </div>
    </div>
    <!-- Insights -->

    <div *ngIf="oldFashion" fxLayout="column" fxFill>

    <div class="drawer-inner-wrapper" fxLayout="column">
      <button mat-flat-button tupMatButton appearance="large" color="primary">
        <mat-icon>add</mat-icon>Create New
      </button>
    </div>
    <div
    class="drawer-inner-wrapper accordion-wrapper"
    fxLayout="column"
    fxFlex
    >
    <mat-accordion displayMode="flat" multi="true" style="margin-left: 12px;">
      <mat-expansion-panel expanded="false" style="box-shadow: none">
        <mat-expansion-panel-header style="padding: 0 8px">
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center"
            ><mat-icon class="material-icons-outlined">dynamic_feed</mat-icon>
            <h4 class="mat-h4">Components</h4></mat-panel-title
          >
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item routerLink="page_buttons">
            <mat-icon matListIcon class="material-icons-outlined">smart_button</mat-icon>
            <h4 class="mat-h4" matLine>Buttons</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_layout">
            <mat-icon matListIcon class="material-icons-outlined">dashboard</mat-icon>
            <h4 class="mat-h4" matLine>Layout</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_form_controls">
            <mat-icon matListIcon class="material-icons-outlined">ballot</mat-icon>
            <h4 class="mat-h4" matLine>Form Controls</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_tables">
            <mat-icon matListIcon class="material-icons-outlined">table_rows</mat-icon>
            <h4 class="mat-h4" matLine>Tables</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_tabs">
            <mat-icon matListIcon class="material-icons-outlined">tab</mat-icon>
            <h4 class="mat-h4" matLine>Tabs</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_title_bar">
            <mat-icon matListIcon class="material-icons-outlined">table_chart</mat-icon>
            <h4 class="mat-h4" matLine>Title Bar</h4>
          </mat-list-item>
          <mat-list-item routerLink="page_typography">
            <mat-icon matListIcon class="material-icons-outlined">keyboard</mat-icon>
            <h4 class="mat-h4" matLine>Typography</h4>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel expanded="false" style="box-shadow: none">
        <mat-expansion-panel-header style="padding: 0 8px">
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center"
            ><mat-icon>insights</mat-icon>
            <h4 class="mat-h4">Insights</h4></mat-panel-title
          >
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item>
            <mat-icon matListIcon>desktop_mac</mat-icon>
            <h4 class="mat-h4" matLine>Dashboard</h4>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListIcon>fingerprint</mat-icon>
            <h4 class="mat-h4" matLine>Targets</h4>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListIcon>pie_chart_outline</mat-icon>
            <h4 class="mat-h4" matLine>Reports</h4>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
      <mat-expansion-panel expanded="false" style="box-shadow: none">
        <mat-expansion-panel-header style="padding: 0 8px">
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center"
            ><mat-icon>mic</mat-icon>
            <h4 class="mat-h4">Audio</h4></mat-panel-title
          >
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item *ngFor="let n of [].constructor(3); let i = index" [routerLink]="'page'+(i+1)">
            <mat-icon matListIcon>bubble_chart</mat-icon>
            <h4 class="mat-h4" matLine>Page {{i+1}}</h4>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
      <mat-expansion-panel expanded="false" style="box-shadow: none">
        <mat-expansion-panel-header style="padding: 0 8px">
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center"
            ><mat-icon>desktop_windows</mat-icon>
            <h4 class="mat-h4">Out of Home</h4></mat-panel-title
          >
        </mat-expansion-panel-header>
        <mat-nav-list>
          <mat-list-item *ngFor="let n of [].constructor(3); let i = index" [routerLink]="'page'+(i+4)">
            <mat-icon matListIcon>bubble_chart</mat-icon>
            <h4 class="mat-h4" matLine>Page {{i+4}}</h4>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
    </div>
    </div>
  </tup-mat-sidebar-content>

  <tup-mat-sidebar-footer-content>
    <div class="sidebar-content" fxLayout="column" fxLayoutGap="15px">
      <fake-account-menu [sideNavMode]="true" [sidebarOpened]="sidebarOpened"></fake-account-menu>
    </div>
  </tup-mat-sidebar-footer-content>

  <!-- main content render -->
  <div class="main-content" fxFlex>

    <tup-mat-rightsidebar [(opened)]="rightSidebarOpened" [autoHide]="false" [closeButton]="oldFashion">

      <!-- right sidebar button, positioned (badly) -->
      <div *ngIf="oldFashion || !hasHiddenTopbar" style="margin-top: 10px; width:100%; text-align: right;">
        <tup-mat-rightsidebar-button [(opened)]="rightSidebarOpened" ></tup-mat-rightsidebar-button>
      </div>

      <!-- menu item to display in the right sidebar once it's been opened -->
      <tup-mat-rightsidebar-menuitem menuName="File" matIconName="folder">
        <div class="menu-content" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" style="width: 200px" >
          <button mat-button tupSearchableItem>New</button>
          <button mat-button tupSearchableItem>Open</button>
          <button mat-button tupSearchableItem>Save</button>
          <button mat-button tupSearchableItem (click)="onToggleButtonText()" *ngIf="isOriginalButtonText">Original button text</button>
          <button mat-button tupSearchableItem (click)="onToggleButtonText()" *ngIf="!isOriginalButtonText">New button text</button>
          <mat-checkbox tupMatCheckbox tupSearchableItem>Another option</mat-checkbox>
          <mat-checkbox tupMatCheckbox tupSearchableItem checked="true">More options</mat-checkbox>
        </div>
      </tup-mat-rightsidebar-menuitem>

      <!-- second menu item for the right sidebar -->
      <tup-mat-rightsidebar-menuitem menuName="Actions" matIconName="auto_fix_high">
        <div class="menu-content" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px" style="width: 200px" >
          <button mat-button tupSearchableItem>Move forwards</button>
          <button mat-button tupSearchableItem>Run Backwards</button>
          <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
        >
          <mat-label tupSearchableItem="STOP">STOP..</mat-label>
          <mat-select value="0">
            <mat-option value="0">In the name of Love</mat-option>
            <mat-option value="1">Collaborate and listen</mat-option>
            <mat-option value="2">Right now, thank you very much</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </tup-mat-rightsidebar-menuitem>

      <!-- third menu item for the right sidebar -->
      <tup-mat-rightsidebar-menuitem menuName="Reports" matIconName="tune" cssClass="reports-menu-item" id="reports-menu-item">
        <div fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="10px" style="width: 200px" >
          <button *ngFor="let icon of rightSidebarReportIcons" tupSearchableItem="{{ icon }}" matTooltip="{{ icon }}" mat-icon-button><mat-icon>{{ icon }}</mat-icon></button>
        </div>
      </tup-mat-rightsidebar-menuitem>

      <tup-mat-rightsidebar-menuitem menuName="Reset" tupSearchableItem matIconName="restart_alt"
        (menuClick)="onResetClick($event)" cssClass="reset-menu-item" id="reset-menu-item">
      </tup-mat-rightsidebar-menuitem>

      <router-outlet></router-outlet>
    </tup-mat-rightsidebar>

  </div>

</tup-mat-furniture>
