<tup-mat-sidebar [hasSidebar]="hasSidebar || !hasTopbar"
                 [hasTopbar]="hasTopbar"
                 [hasHiddenTopbar]="hasHiddenTopbar"
                 [appVersion]="appVersion"
                 [sidebarOpenState]="sidebarOpenState"
                 (sidebarOpened)="onSidebarOpened($event)"
                 [sidebarMode]="sidebarMode"
                 (toggleSidebar)="toggleSidebar()"
                 (logoClick)="onLogoClick()">

    <tup-mat-sidebar-content fxFlex="auto">
        <ng-content select="tup-mat-sidebar-content"></ng-content>
    </tup-mat-sidebar-content>

    <tup-mat-sidebar-footer-content>
      <ng-content select="tup-mat-sidebar-footer-content"></ng-content>
    </tup-mat-sidebar-footer-content>

    <tup-mat-topbar-content *ngIf="!hasTopbar && hasHiddenTopbar">
      <ng-content select="tup-mat-topbar-content.hidden-top-bar"></ng-content>
    </tup-mat-topbar-content>

    <ng-content></ng-content>
</tup-mat-sidebar>

<tup-mat-topbar *ngIf="hasTopbar"
  [hasSidebar]="hasSidebar"
  [sidebarOpenState]="sidebarOpenState"
  (toggleSidebar)="toggleSidebar()"
  (logoClick)="onLogoClick()">

    <tup-mat-topbar-content>
        <ng-content select="tup-mat-topbar-content"></ng-content>
    </tup-mat-topbar-content>
</tup-mat-topbar>
