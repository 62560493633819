import { Component, OnInit } from '@angular/core';
import { AttachSession } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-page4',
  templateUrl: './page4.component.html',
  styleUrls: ['./page4.component.scss']
})
export class Page4Component implements OnInit {
  fauxData1: any[] = ["Oranges","Apples","Peaches","Strawberries","Kumquat","Persimmon"].map((name: string) => { return { name, value: Math.floor(Math.random() * 18) + 2 }; });
  fauxData2: any[] = ["Sneakers","Crocs","Galoshes","Moccasins","Oxfords","Cleats","Flip-Flops"].map((name: string) => { return { name, value: Math.floor(Math.random() * 18) + 2 }; });
  fauxTable: any[] = ["Total", "Oranges", "Peaches", "Apples"].map((title: string) => {
    return { name: title, totals: [250975, Math.floor(Math.random() * 370000), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2)], men: [250975, Math.floor(Math.random() * 370000), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2), (Math.random() * 100.00).toFixed(2)] }
  });

  displayedColumns: string[] = ['name', 'labels', 'totals', 'men'];

  scheme: any = { domain: ['#276ef1'] };
  constructor() { }

  ngOnInit(): void {

  }

}
