<tup-mat-content-wrapper fxLayout="column">
  <sidebar-wrapper fxFlex>
    <div mainContent fxLayout="column" fxLayoutGap="13px">
      <tup-mat-title-bar
        title="Create new target"
        subtitle="Start building your target audience"
        infoText="Search for and select your target below"
      ></tup-mat-title-bar>
      <tup-mat-card
        fxLayout="row"
        fxLayoutGap="13px"
        fxLayoutAlign="start center"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
          fxFlex
        >
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Enter a word or phrase to search</mat-label>
          <input matInput placeholder="Search" />
          <mat-icon matSuffix>close</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" tupMatFormField>
          <mat-label>Category</mat-label>
          <mat-select>
            <mat-option value="All">All categories</mat-option>
            <mat-option value="People">People</mat-option>
            <mat-option value="Animals">Animals</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button color="primary" tupMatButton appearance="large">
          Search
        </button>
      </tup-mat-card>
      <tup-mat-card fxLayout="column">
        <div fxFlex fxLayout="row" fxLayoutGap="13px">
          <div fxFlex>
            <h4>Results</h4>
          </div>
          <div fxFlex>
            <h4>Category matches</h4>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <button mat-flat-button color="primary" tupMatButton appearance="large">
            Add target
          </button>
        </div>
      </tup-mat-card>
    </div>
    <div sideContent fxLayout="column" fxLayoutGap="13px">
      <tup-mat-card
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="13px"
      >
        <mat-icon
          color="primary"
          style="font-size: 64px; height: 64px; width: 64px"
          >account_circle_outline</mat-icon
        >
        <div class="tup-info-text">
          <p class="mat-caption">Total Audience</p>
          <p class="mat-body-2">252,092</p>
          <p class="mat-caption">(000)</p>
        </div>
      </tup-mat-card>
      <tup-mat-card fxLayout="column">
        <h4 class="mat-h4">My Target</h4>
        <table>
          <tr>
            <td class="mat-body-1">Audience (000)</td>
            <td style="text-align: right" class="mat-body-2">0</td>
          </tr>
          <tr>
            <td class="mat-body-1">Resps</td>
            <td style="text-align: right" class="mat-body-2">0</td>
          </tr>
          <tr>
            <td class="mat-body-1">Percentage %</td>
            <td style="text-align: right" class="mat-body-2">0.0</td>
          </tr>
        </table>
        <p class="mat-body-2">
          My target library (0)
          <mat-icon color="primary">info_outline</mat-icon>
        </p>
      </tup-mat-card>
    </div>
  </sidebar-wrapper>
</tup-mat-content-wrapper>
