<button 
    mat-flat-button 
    tupMatButton
    color="primary"  
    appearance="nano" 
    matTooltip="Open Menu"
    (click)="onOpenChanged()" 
    >
       <mat-icon>menu_open</mat-icon>
</button>
