<div mat-tab-nav-bar #ichabodsHead>
  <ng-container *ngFor="let tabLabel of tabLabels; let idx=index;">
    <div
      mat-tab-link
      (click)="onTabClick(idx)"
      [active]="_activeTabIndex == idx"
      [class.tup-mat-tab-label-active]="_activeTabIndex == idx"
      class="tup-mat-tab-label"
    >
      <ng-container *ngTemplateOutlet="tabTemplates[idx]; context:{ label: tabLabel }"></ng-container>
  </div>
  </ng-container>

  <ng-template #defaultTemplate let-label="label">
    {{label}}
  </ng-template>

</div>
