<div fxLayout="row" fxLayoutAlign="start center">

  <mat-divider vertical="true"></mat-divider>
  <button mat-mini-fab class="mat-elevation-z0 user-menu" [class.side-nav-mode]="sideNavMode" (click)="menuTrigger.openMenu()" fxHide fxShow.gt-sm fxShow.sm color="primary">
      <mat-icon class="material-icons-outlined">account_circle</mat-icon>
    </button>

  <div class="user-info" [class.side-nav-mode]="sideNavMode" [class.side-nav-opened]="sidebarOpened">
      <p class="name-spacing">{{ user?.attributes?.name }}</p>
      <p class="email-spacing">{{ user?.attributes?.email }}</p>
  </div>
  <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu" [class.side-nav-mode]="sideNavMode" [class.side-nav-opened]="sidebarOpened">
      <mat-icon>expand_less</mat-icon>
  </button>
</div>

<mat-menu fxLayout="column" class="account-menu" #userMenu="matMenu">
  <!-- <mat-list-item style="text-align: center;">
      <mat-icon color="primary" id="dropdown-user-picture">account_circle</mat-icon>
  </mat-list-item> -->
  <mat-list-item>
      <h3 matLine class="account-title">Name</h3>
      <p matLine class="account-info" title="{{ user?.attributes?.name }}">{{ user?.attributes?.name }}</p>
  </mat-list-item>
  <mat-list-item>
      <h3 matLine class="account-title">Email</h3>
      <p matLine class="account-info" title="{{ user?.attributes?.email }}">{{ user?.attributes?.email }}</p>
  </mat-list-item>
  <mat-list-item>
      <h3 matLine class="account-title">Company</h3>
      <p matLine class="account-info" title="{{ currentContainer }}">{{ currentContainer }}</p>
  </mat-list-item>
</mat-menu>
