import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy,OnInit {

  hasSidebar: boolean = true;
  oldFashion: boolean = false;
  hasHiddenTopbar: boolean = true;
  rightSidebarOpened: boolean = false;
  sidebarOpened: boolean = false;

  dataOrGraph: boolean = true;

  rightSidebarReportIcons: string[] = [ 'file_download', 'file_upload', 'exit_to_app', 'ios_share', 'get_app', 'import_export', 'upgrade', 'system_update_alt', 'send_to_mobile', 'file_download_off' ]

  isOriginalButtonText = true;

  constructor(private mediaObserver: MediaObserver) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  onToggleSideBar(opened: boolean){
    this.sidebarOpened = opened;
  }

  onToggleRightSideBar(opened: boolean){
    this.rightSidebarOpened = opened;
  }

  onLogoClick(){
    this.oldFashion = !this.oldFashion;
  }

  onResetClick(e: string){
    alert(e);
  }

  onToggleButtonText(): void {
    this.isOriginalButtonText = !this.isOriginalButtonText;
  }
}
