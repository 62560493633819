<tup-mat-content-wrapper gap="0" overflow="auto" fxLayout="column" fxFill>
  <tup-mat-content-wrapper gap="33">
    <h2>Menus</h2>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle" gap="33" overflow="auto">
    <button mat-stroked-button tupMatButton #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="defaultMenu" (click)="$event.stopPropagation(); $event.preventDefault();" color="primary">
      Default <mat-icon>expand_more</mat-icon>
    </button>

    <button mat-stroked-button tupMatButton #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="smallMenu" (click)="$event.stopPropagation(); $event.preventDefault();" color="primary">
      Small <mat-icon>expand_more</mat-icon>
    </button>

    <mat-menu #defaultMenu="matMenu">
      <button mat-menu-item>
        <mat-icon>play_circle_outline</mat-icon>
        <span>Open</span>
      </button>

      <button mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>

      <button mat-menu-item>
        <mat-icon>highlight_off</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>

    <mat-menu #smallMenu="matMenu" class="tup-mat-menu-panel-small">
      <button mat-menu-item>
        <mat-icon>play_circle_outline</mat-icon>
        <span>Open</span>
      </button>

      <button mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>

      <button mat-menu-item>
        <mat-icon>highlight_off</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>
