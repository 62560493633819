<tup-mat-content-wrapper gap="0" overflow="auto" fxLayout="column" fxFill>
  
  <tup-mat-content-wrapper gap="33">
    <h2>Toggle Buttons</h2>
    <p>Toggle buttons are styled using the material button toggle classes themselves, so a directive does not need to be added</p>
    <mat-button-toggle-group aria-label="Font Style">
      <mat-button-toggle value="bold">Bold</mat-button-toggle>
      <mat-button-toggle value="italic">Italic</mat-button-toggle>
      <mat-button-toggle value="strikethrough">Strikethrough</mat-button-toggle>
      <mat-button-toggle checked value="underline">Underline</mat-button-toggle>
    </mat-button-toggle-group>  
  </tup-mat-content-wrapper>

  <tup-mat-content-wrapper position="middle" gap="33" overflow="auto">
    <h2>Buttons</h2>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="middle" gap="33" overflow="auto">
    <ng-container *ngFor="let size of ['large', 'medium', 'small', 'tiny', 'nano']">
      <h3>{{size | titlecase}}</h3>
      <table mat-table [dataSource]="buttonTypes">

        <!--
          Can this table be improved?
          The only difference between each container is the mat-*-button directive & color.
        -->

        <ng-container matColumnDef="PrimaryFlat">
          <th mat-header-cell *matHeaderCellDef>TUP Primary Flat</th>
          <td mat-cell *matCellDef="let button">
            <button mat-flat-button color="primary" tupMatButton appearance="{{size}}" [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="PrimaryStroked">
          <th mat-header-cell *matHeaderCellDef>TUP Primary Stroked</th>
          <td mat-cell *matCellDef="let button">
            <button mat-stroked-button color="primary" tupMatButton appearance="{{size}}"
              [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="AccentFlat">
          <th mat-header-cell *matHeaderCellDef>TUP Accent Flat</th>
          <td mat-cell *matCellDef="let button">
            <button mat-flat-button color="accent" tupMatButton appearance="{{size}}" [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="AccentStroked">
          <th mat-header-cell *matHeaderCellDef>TUP Accent Stroked</th>
          <td mat-cell *matCellDef="let button">
            <button mat-stroked-button color="accent" tupMatButton appearance="{{size}}" [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="WarnFlat">
          <th mat-header-cell *matHeaderCellDef>TUP Warn Flat</th>
          <td mat-cell *matCellDef="let button">
            <button mat-flat-button color="warn" tupMatButton appearance="{{size}}" [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="WarnStroked">
          <th mat-header-cell *matHeaderCellDef>TUP Warn Stroked</th>
          <td mat-cell *matCellDef="let button">
            <button mat-stroked-button color="warn" tupMatButton appearance="{{size}}" [disabled]="button.isDisabled">
              {{button.isIconButton ? '' : button.isDisabled ? 'Disabled ' : size | titlecase}}
              <mat-icon ng-if="button.isIconButton">save</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="example-button-row"></tr>
      </table>
    </ng-container>
  </tup-mat-content-wrapper>
  <tup-mat-content-wrapper position="last" fxFlex gap="33" fxLayout="row wrap" overflow="auto">
  </tup-mat-content-wrapper>
</tup-mat-content-wrapper>
