import { Component, OnInit } from '@angular/core';


export interface FakeDoc {
  name: string;
  audience: number;
  responses: number;
  percent: number;
  createdBy: string;
  createdOn: string;
  status: string;
}

@Component({
  selector: 'app-page2',
  templateUrl: './page2.component.html',
  styleUrls: ['./page2.component.scss']
})
export class Page2Component implements OnInit {
  displayedColumns: string[] = ['status','name', 'audience', 'responses', 'percent','createdBy','createdOn'];
  dataSource1: FakeDoc[] = [
    { name: "Lorem Ipsum", audience: 12405, responses: 16700, percent: 0.5, createdBy: "John", createdOn: "2021/07/02", status: "" },
    { name: "Donec in nisi vel elit", audience: 6, responses: 2000, percent: 10, createdBy: "John", createdOn: "2021/07/05", status: "" },
    { name: "Nulla elementum lorem", audience: 234, responses: 567, percent: 0.5, createdBy: "John", createdOn: "2021/07/02", status: "" },
    { name: "Sit Amentus", audience: 6, responses: 456, percent: 3, createdBy: "John", createdOn: "2021/07/05", status: "" }
  ];
  dataSource2: FakeDoc[] = [
    { name: "Air Canada", audience: 12405, responses: 16700, percent: 0.5, createdBy: "John", createdOn: "2021/07/02", status: "" },
    { name: "Adults who drink beer", audience: 6, responses: 2000, percent: 10, createdBy: "John", createdOn: "2021/07/05", status: "" }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
